const REVIEW_TYPE_IDS = {
  REVIEW_TYPE_NEGATIVE: 0, // негативный
  REVIEW_TYPE_NEUTRAL: 1, // нейтральный
  REVIEW_TYPE_POSITIVE: 2, // положительный
}

const REVIEW_TYPE_LIST = [
  { id: REVIEW_TYPE_IDS.REVIEW_TYPE_NEGATIVE, value: 'Негативный' },
  { id: REVIEW_TYPE_IDS.REVIEW_TYPE_NEUTRAL, value: 'Нейтральный' },
  { id: REVIEW_TYPE_IDS.REVIEW_TYPE_POSITIVE, value: 'Позитивный' },
]

const REVIEW_STATUS_IDS = {
  REVIEW_STATUS_MODERATING: 0, // На модерации
  REVIEW_STATUS_APPROVED: 1, // Одобренный
  REVIEW_STATUS_REJECTED: 2, // Отклоненный
}

const REVIEW_STATUS_LIST = [
  { id: REVIEW_STATUS_IDS.REVIEW_STATUS_MODERATING, value: 'На модерации' },
  { id: REVIEW_STATUS_IDS.REVIEW_STATUS_APPROVED, value: 'Одобренный' },
  { id: REVIEW_STATUS_IDS.REVIEW_STATUS_REJECTED, value: 'Отклоненный' },
]

export default {
  // Тип отзыва
  REVIEW_TYPE_IDS,
  REVIEW_TYPE_LIST,

  // Статус отзыва
  REVIEW_STATUS_IDS,
  REVIEW_STATUS_LIST,
}
